import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import Header from "../components/header"
import scrollTo from "gatsby-plugin-smoothscroll"

const ContactPage = () => (
  <>
    <Seo title={"Our team"} description={"Team of Dryline Partners"} />
    <Header />

    <div className="text-white bg-black py-20 lg:p-20 overflow-hidden">
      <div className="container mx-auto lg:px-20 relative">
        <div className="max-w-3xl overflow-hidden mx-10 lg:mx-20">
          <h3 data-sal="fade" data-sal-duration="1000" className="text-4xl lg:text-6xl font-semibold text-white py-20">
            Let us know more about you and how we can connect.
          </h3>
          <form
            data-sal="fade"
            data-sal-duration="1000"
            data-sal-delay="200"
            className="font-serif"
            action="/success"
            name="contact"
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
          >
            <input type="hidden" name="form-name" value="contact" />
            <div className="flex flex-col lg:flex-row items-start lg:items-end py-5">
              <p className="min-w-max">My name is, </p>
              <input
                className="outline-none border-peach focus:border-white border-b min-w-full lg:ml-10 mb-1 bg-transparent"
                required
                type="text"
                name="name"
              />
            </div>
            <div className="flex flex-col lg:flex-row items-start lg:items-end py-5">
              <p className="min-w-max">I'm a/an </p>
              <input
                className="outline-none border-peach focus:border-white border-b min-w-full lg:ml-5 mb-1 bg-transparent"
                type="detail"
                name="detail"
              />
            </div>
            <div className="flex flex-col lg:flex-row items-start lg:items-end py-5">
              <p className="min-w-max">Please contact me about </p>
              <input
                className="outline-none border-peach focus:border-white border-b min-w-full lg:ml-5 mb-1 bg-transparent"
                type="message"
                name="message"
              />
            </div>
            <div className="flex flex-col lg:flex-row items-start lg:items-end py-5">
              <p className="min-w-max">Email me at </p>
              <input
                className="outline-none border-peach focus:border-white border-b min-w-full lg:ml-5 mb-1 bg-transparent"
                required
                type="email"
                name="email"
              />
            </div>
            <div className="w-full text-right py-5">
              <button className="text-purple text-xl" type="submit">→ SEND</button>
            </div>
          </form>
        </div>
        <div className="-right-32 bottom-20 absolute">
          <StaticImage
            src="../images/contact.png"
            width={400}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="tio"
          />
        </div>
      </div>
    </div>
    <div className="relative">
      <div className="ticker-wrap">
        <Link className="ticker z-10" to="/contact">
          <div className="ticker-item">
            <p className="text-white" to="/contact">OPEN CALL FOR TECH COMPANIES</p>
          </div>
          <div className="ticker-item">
            <p className="text-black" to="/contact">OPEN CALL FOR TECH COMPANIES</p>
          </div>
          <div className="ticker-item">
            <p className="text-white" to="/contact">OPEN CALL FOR TECH COMPANIES</p>
          </div>
          <div className="ticker-item">
            <p className="text-black" to="/contact">OPEN CALL FOR TECH COMPANIES</p>
          </div>
        </Link>
        <Link className="ticker z-10 " to="/contact">
          <div className="ticker-item">
            <p className="text-white" to="/contact">OPEN CALL FOR TECH COMPANIES</p>
          </div>
          <div className="ticker-item">
            <p className="text-black" to="/contact">OPEN CALL FOR TECH COMPANIES</p>
          </div>
          <div className="ticker-item">
            <p className="text-white" to="/contact">OPEN CALL FOR TECH COMPANIES</p>
          </div>
          <div className="ticker-item">
            <p className="text-black" to="/contact">OPEN CALL FOR TECH COMPANIES</p>
          </div>
        </Link>
      </div>
    </div>
    <div className="flex relative flex-wrap w-full py-20 lg:pt-12 lg:pb-36 bg-black text-lg text-white font-serif">
      <div className="block  lg:w-1/2 px-10 text-left lg:py-20 lg:px-44 ">
        <StaticImage
          src="../images/logo-white.png"
          width={200}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="tio"
        />
      </div>

      <div className="block w-full lg:w-1/2 p-10 lg:p-20 ">
        <div className="flex w-full">
          <div className="w-full lg:w-1/2">
            <Link className="w-full block my-5" to="/about">
              <span className="border-b border-transparent hover:border-white">
                About
              </span>
            </Link>
            <Link className="w-full block my-5" to="/strategy">
              <span className="border-b border-transparent hover:border-white">
                Strategy
              </span>
            </Link>
          </div>
          <div className="w-full lg:w-1/2">
            <Link className="w-full block my-5" to="/team">
              <span className="border-b border-transparent hover:border-white">
                Team
              </span>
            </Link>
            <Link className="w-full block my-5" to="/companies">
              <span className="border-b border-transparent hover:border-white">
                Companies
              </span>
            </Link>
          </div>
          <div className="w-full lg:w-1/2">
            <Link className="w-full block my-5" to="/news">
              <span className="border-b border-transparent hover:border-white">
                News
              </span>
            </Link>
            <Link className="w-full block my-5" to="/contact">
              <span className="border-b border-transparent hover:border-white">
                Contact
              </span>
            </Link>
          </div>
        </div>
      </div>

      <div className="absolute z-50 top-12 right-6 lg:bottom-12 lg:right-24">
        <button onClick={() => scrollTo("#navbar")}>
          <StaticImage
            src="../images/scroll-up.png"
            width={100}
            alt="scroll-up"
          />
        </button>
      </div>
    </div>

    <div className="block bg-black">
      <div className="mx-10 lg:mx-20 py-5 justify-between items-center border-t border-peach flex flex-col lg:flex-row">
        <div className="font-serif lg:w-1/2 flex text-left items-end justify-items-start">
          <a
            className="p-2 flex items-center"
            rel="noreferrer"
            target="_blank"
            href="https://www.linkedin.com/company/dry-line-partners/"
          >
            <StaticImage
              src={"../images/linkedin-white.png"}
              width={15}
              formats={["auto", "webp", "avif"]}
              alt="Linkedin"
            />
            <span className="hidden lg:block px-3 text-white">Linkedin</span>
          </a>
          <a
            className="p-2 flex items-center"
            rel="noreferrer"
            target="_blank"
            href="https://twitter.com/DryLinePartners"
          >
            <StaticImage
              src={"../images/twitter-white.png"}
              width={15}
              formats={["auto", "webp", "avif"]}
              alt="Twitter"
            />
            <span className="hidden lg:block px-3 text-white">Twitter</span>
          </a>
        </div>
        <div className="text-white font-serif pt-10 lg:pt-0">
          © Dry Line Partners. All rights reserved.
        </div>
      </div>
    </div>
  </>
)

export default ContactPage
